/* eslint-disable */
function equalHeights() {
  typeof lozad != 'function' &&
    (jQuery('div.product-items').each(function () {
      var n = 0;
      jQuery('.product-item', this).each(function (t, i) {
        var r = jQuery(i).removeAttr('style').height();
        r > n && (n = r);
      });
      jQuery(this).attr('data-itemsHeight', n);
    }),
    jQuery('div.product-items').each(function (n, t) {
      var i = jQuery(t).attr('data-itemsHeight');
      i != undefined && i + '' != '' && jQuery(t).find('.product-item').height(i);
    }));
}

if (typeof jQuery === 'function') {
  jQuery(window).on('resize', equalHeights);
}
/* eslint-enable */

const fieldsForRelated = [
  'Finish Family',
  'Blade Span',
  'Fan Lights',
  'Indoor & Outdoor',
  'Ceiling Height',
  'Styles',
  'Mount Type',
];
const escapeSpaces = (v) => v.replace(/ /g, '_');
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[escapeSpaces(field)];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 48,
  },
  requestTimeout: 40000,
  facets: {
    simpleFacet: { fields: ['stock'], name: 'simpleRadioFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#categoryItemsBlock',
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.site-header #searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'RelatedItems',
      location: { insertAfter: '#listing .relatedBlock .scroll-bar-container' },
      template: 'product/relatedItems',
      count: 8,
      getSelection,
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
      pageSizes: [24, 48, 96, 'Show All'],
    },
    {
      name: 'FilterSearchBox',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      updateCallback: equalHeights,
    },
  ],
};
